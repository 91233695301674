import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Typography,
  StepContent
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

import Planos from './components/Planos/Planos';
import DadosCadastrais from './components/DadosCadastrais/DadosCadastrais';

import { UpdateConsultant } from '../../Services/Consultants';
import { CreatePlans } from '../../Services/Plans';
import { GetConsultant } from '../../Services/Auth';

import './styles.scss';

const Onboarding = () => {
  const navigate = useNavigate();
  const consultant = JSON.parse(sessionStorage.getItem('consultant'));

  const steps = [
    {
      id: 'dadosCadastrais',
      title: 'Dados Cadastrais',
      component: [
        {
          id: 1,
          title: 'Registro Profissional',
          component: (
            <Box p={1}>
              <DadosCadastrais
                currentSubStep={0}
                onDataChange={(data, valid) => handleDataChange('dadosCadastrais', data, valid)}
              />
            </Box>
          )
        },
        {
          id: 2,
          title: 'Ocupação',
          component: (
            <Box p={1}>
              <DadosCadastrais
                currentSubStep={1}
                onDataChange={(data, valid) => handleDataChange('dadosCadastrais', data, valid)}
              />
            </Box>
          )
        },
        {
          id: 3,
          title: 'Descrição',
          component: (
            <Box p={1}>
              <DadosCadastrais
                currentSubStep={2}
                onDataChange={(data, valid) => handleDataChange('dadosCadastrais', data, valid)}
              />
            </Box>
          )
        },
        {
          id: 4,
          title: 'Foto de Perfil',
          component: (
            <Box p={1}>
              <DadosCadastrais
                currentSubStep={3}
                onDataChange={(data) => handleDataChange('dadosCadastrais', data, true)}
              />
            </Box>
          )
        }
      ],
    },
    {
      id: 'planos',
      title: 'Sua Consultoria',
      component: [
        {
          id: 1,
          title: 'Nome do Plano',
          component: (
            <Box p={1}>
              <Planos currentSubStep={0} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        },
        {
          id: 2,
          title: 'Descrição',
          component: (
            <Box p={1}>
              <Planos currentSubStep={1} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        },
        {
          id: 3,
          title: 'Duração',
          component: (
            <Box p={1}>
              <Planos currentSubStep={2} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        },
        {
          id: 4,
          title: 'Preço',
          component: (
            <Box p={1}>
              <Planos currentSubStep={3} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        },
        {
          id: 5,
          title: 'Atividades',
          component: (
            <Box p={1}>
              <Planos currentSubStep={4} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        },
        {
          id: 6,
          title: 'Anamnese',
          component: (
            <Box p={1}>
              <Planos currentSubStep={5} onDataChange={(data) => handleDataChange('planos', data)} />
            </Box>
          )
        }
      ],
    }
  ];

  const [currentMainStep, setCurrentMainStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [formData, setFormData] = useState(() => {
    // Recuperar dados salvos do sessionStorage
    const savedDadosCadastrais = sessionStorage.getItem('dados_cadastrais_form_values');
    const savedPlanos = sessionStorage.getItem('planos_form_values');

    return {
      dadosCadastrais: savedDadosCadastrais ? JSON.parse(savedDadosCadastrais) : {},
      planos: savedPlanos ? JSON.parse(savedPlanos) : {}
    };
  });
  const [isValid, setIsValid] = useState(false);

  const handleDataChange = (step, data, valid = true) => {
    setIsValid(valid);
    setFormData(prev => ({
      ...prev,
      [step]: data
    }));
  };

  const handleFinish = async () => {
    try {
      const access_token = localStorage.getItem('access_token');
      const consultant = JSON.parse(sessionStorage.getItem('consultant'));

      // Atualiza dados do consultor
      const consultantPayload = {
        ...consultant,
        credentialType: formData.dadosCadastrais.credentialType,
        credential: formData.dadosCadastrais.credential,
        occupation: formData.dadosCadastrais.occupation,
        description: formData.dadosCadastrais.description
      };
      await UpdateConsultant(access_token, consultantPayload);

      // Cria plano inicial
      const planPayload = {
        name: formData.planos.name,
        price: formData.planos.price,
        weeks: formData.planos.duration.id,
        description: formData.planos.description,
        available: true,
        training: formData.planos.activities.training,
        diet: formData.planos.activities.diet,
        inLoco: formData.planos.activities.inLoco,
        recurring: formData.planos.recurring,
        quiz: formData.planos.questionario
      };
      await CreatePlans(access_token, planPayload);

      // GTM - Onboarding Complete
      window.dataLayer.push({
        'event': 'onboarding_complete',
        'consultant_id': consultant.id
      });

      setTimeout(async () => {
        const consultant = await GetConsultant(access_token);
        sessionStorage.setItem('consultant', JSON.stringify(consultant.data));
      }, 500);

      localStorage.setItem('onboardingComplete', 'true');
      navigate('/onboarding-success');

      // Limpar dados do sessionStorage após finalizar com sucesso
      sessionStorage.removeItem('dados_cadastrais_form_values');
      sessionStorage.removeItem('planos_form_values');
    } catch (error) {
      console.error('Erro ao finalizar onboarding:', error);
    }
  };

  const handleNextSubStep = () => {
    if (!isValid) return;

    // GTM - Track Step Progress
    window.dataLayer.push({
      'event': 'onboarding_step_complete',
      'step_main': steps[currentMainStep].title,
      'step_sub': steps[currentMainStep].component[currentSubStep].title,
      'consultant_id': consultant?.id,
      'step_number': `${currentMainStep + 1}.${currentSubStep + 1}`
    });

    if (currentMainStep === steps.length - 1 &&
      currentSubStep === steps[currentMainStep].component.length - 1) {
      handleFinish();
    } else {
      if (currentSubStep < steps[currentMainStep].component.length - 1) {
        setCurrentSubStep(prev => prev + 1);
      } else {
        if (currentMainStep < steps.length - 1) {
          // GTM - Track Main Step Complete
          window.dataLayer.push({
            'event': 'onboarding_main_step_complete',
            'step_name': steps[currentMainStep].title,
            'consultant_id': consultant?.id,
            'step_number': currentMainStep + 1
          });

          setCurrentMainStep(prev => prev + 1);
          setCurrentSubStep(0);
        }
      }
    }
  };

  const handlePrevSubStep = () => {
    // GTM - Track Step Back
    window.dataLayer.push({
      'event': 'onboarding_step_back',
      'step_main': steps[currentMainStep].title,
      'step_sub': steps[currentMainStep].component[currentSubStep].title,
      'consultant_id': consultant?.id,
      'step_number': `${currentMainStep + 1}.${currentSubStep + 1}`
    });

    if (currentSubStep > 0) {
      setCurrentSubStep(prev => prev - 1);
    } else {
      if (currentMainStep > 0) {
        setCurrentMainStep(prev => prev - 1);
        const previousComponent = steps[currentMainStep - 1].component;
        const { subSteps: prevSubSteps } = previousComponent({
          onDataChange: (data, valid) => handleDataChange(steps[currentMainStep - 1].id, data, valid)
        });
        setCurrentSubStep(prevSubSteps.length - 1);
      }
    }
  };

  // Adicionar timestamp de início do onboarding
  React.useEffect(() => {
    if (!sessionStorage.getItem('onboarding_start_time')) {
      sessionStorage.setItem('onboarding_start_time', Date.now());

      // GTM - Onboarding Start
      window.dataLayer.push({
        'event': 'onboarding_start',
        'consultant_id': consultant?.id
      });
    }
  }, [consultant?.id]);

  return (
    <Box className="onboarding-wrapper">
      <Paper className="onboarding-container">
        <Box className="sidebar">
          <Box sx={{ p: 3 }}>
            <Typography
              variant="h5"
              sx={{
                color: '#662d91',
                fontWeight: 600,
                mb: 1
              }}
            >
              Bem-vindo, {consultant?.name?.split(' ')[0]}!
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary' }}
            >
              Vamos configurar sua conta para você começar
            </Typography>
          </Box>
          <Stepper
            activeStep={currentMainStep}
          >
            {steps.map((step, index) => (
              <Step key={step.id}>
                <StepLabel
                  StepIconProps={{
                    completed: index < currentMainStep,
                    icon: index < currentMainStep ? <CheckCircleIcon /> : index + 1,
                  }}
                >
                  {step.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box className="content">
          <Box className="step-content">
            <Typography variant="h5" className="step-title">
              {steps[currentMainStep].title}
            </Typography>

            <Box className="sub-steps">
              <Stepper
                activeStep={currentSubStep}
                orientation="vertical"

              >
                {steps[currentMainStep].component?.map((subStep, index) => (
                  <Step key={`${steps[currentMainStep].id}-${index}`}>
                    <StepLabel>{subStep.title}</StepLabel>
                    <StepContent>
                      <Box>
                        {steps[currentMainStep].component[currentSubStep]?.component}
                      </Box>
                      <Box className="navigation-buttons">
                        <Button
                          sx={{
                            textTransform: 'none',
                            borderRadius: '25px',
                            padding: '12px 30px',
                          }}
                          variant="outlined"
                          onClick={handlePrevSubStep}
                          disabled={currentMainStep === 0 && currentSubStep === 0}
                        >
                          Anterior
                        </Button>
                        <Box sx={{ display: 'flex' }}>
                          {currentMainStep === 0 && currentSubStep === 3 && (
                            <Button
                              variant="text"
                              sx={{
                                textTransform: 'none',
                                borderRadius: '25px',
                                padding: '12px 30px',
                                color: 'text.secondary',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                              onClick={() => {
                                window.dataLayer.push({
                                  'event': 'skip_profile_picture',
                                  'consultant_id': consultant?.id
                                });
                                handleNextSubStep();
                              }}
                            >
                              Pular
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            onClick={handleNextSubStep}
                            disabled={!isValid}
                            sx={{
                              textTransform: 'none',
                              borderRadius: '25px',
                              padding: '12px 30px',
                            }}
                          >
                            {currentMainStep === steps.length - 1 &&
                              currentSubStep === steps[currentMainStep].component.length - 1
                              ? 'Finalizar'
                              : 'Próximo'}
                          </Button>
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>

          </Box>

        </Box>
      </Paper>
    </Box>
  );
};

export default Onboarding;