/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';

import People from '../People/People';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import ReactCalendar from '../../../../Components/ReactCalendar/ReactCalendar';
import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";
import ModalAddSchedule from "./ModalAddSchedule/ModalAddSchedule.jsx";

import {GetSchedules} from "../../../../Services/Schedules/index";

import './Calendar.scss';

const Calendar = () => {
    const access_token = localStorage.getItem('access_token');

    const [schedules, setSchedules] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalSchedule = () => {
        setIsModalOpen(true);
    };

    const closeSchedule = (reload) => {
        if (reload) {
            getData();
        }
        setIsModalOpen(false);
    };


    const changeDate = (date) => {
        setSchedules([]);
        setCurrentDate(date);
    };

    const addHours = (date, hours) => {
        date.setHours(date.getHours() + hours);
        return date;
    };

    const formatDate = (row) => {
        // Função para formatar o número de dois dígitos
        const formatNumberTwoDigits = (number) => {
            return number.toString().padStart(2, '0');
        }

        // Converter as datas para objetos Date
        const startDate = new Date(row.ScheduleStart);
        const endDate = new Date(row.ScheduleEnd);

        // Extrair informações de hora e minutos
        const startHours = formatNumberTwoDigits(startDate.getUTCHours());
        const startMinutes = formatNumberTwoDigits(startDate.getUTCMinutes());
        const endHours = formatNumberTwoDigits(endDate.getUTCHours());
        const endMinutes = formatNumberTwoDigits(endDate.getUTCMinutes());

        // Formatar as informações no formato desejado
        const formattedSchedule = `Das ${startHours}:${startMinutes} às ${endHours}:${endMinutes}`;
        return formattedSchedule;
    };

    const getData = async () => {
        const now = new Date();
        const date = new Date(currentDate.setHours(0, 0, 0, 0));
        const dateFormat = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const response = await GetSchedules(access_token, dateFormat);

        const dataParsed = response.data.rows.map(row => {
            const formattedSchedule = formatDate(row);
            return {
                id: row?.id,
                name: row?.name,
                student: row?.student,
                location: row.location,
                description: row.description,
                schedule: formattedSchedule,
                past: now > addHours(new Date(row.ScheduleEnd), 3),
            };
        });
        setSchedules(dataParsed)
    }
    useEffect(() => {
        getData();
    }, [currentDate]);

    return (
        <div className="calendar">
            <div className='calendar-title'>
                <div className='title-left'>
                    <Title title="Calendário"/>
                    <Subtitle subtitle="Seus próximos compromissos presenciais"/>
                </div>
                <PrimaryButton
                    text="Novo Compromisso"
                    onClick={openModalSchedule}
                />
            </div>
            <ModalAddSchedule open={isModalOpen} handleModal={closeSchedule}/>
            <div className='section-calendar'>
                <ReactCalendar onChange={changeDate} value={currentDate}/>
            </div>

            <div className='calendar-divider'/>

            <div className='calendar-commitments'>
                <strong>Compromissos do dia ({schedules.length})</strong>
                <span>{currentDate.toLocaleString().substring(0, 10)}</span>
            </div>

            <div className='calendar-people'>
                {schedules ? (
                    schedules.map((item, key) => (
                        <People
                            key={key}
                            id={item.id}
                            name={item.name}
                            description={item.description}
                            schedule={item.schedule}
                            past={item.past}
                            image={item.image}
                            student={item.student}
                            callback={getData}
                        />
                    ))
                ) : (
                    <p>Você não tem nenhum compromisso na data {currentDate.toLocaleString().substring(0, 10)}.</p>
                )}
            </div>
        </div>
    );
}

export default Calendar;