import React, {useContext, useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import {Box, Tab, Tabs} from '@mui/material';
import {TabContext, TabPanel} from '@mui/lab';

import {AppContext} from "../../Helpers/Context";

import {GetDashboardInfo} from '../../Services/Dashboard';

import Header from "../../Components/Header/Header";
import MyData from "./components/MyData/MyData";
import ProfileInfo from "./components/ProfileInfo/ProfileInfo";
import Password from "./components/Password/Password";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
// import Plans from "./components/Plans/Plans";
import Finance from "./components/Finance/Finance";

import "./ManageAccount.scss";

const ManageAccount = () => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const {signOut} = useContext(AppContext);
    const location = useLocation();

    const [imagePath, setImagePath] = useState('.');

    const [value, setValue] = useState('1');
    const [profile, setProfile] = useState({
        name: consultant.name,
        occupation: consultant.occupation,
        students: 0,
        newStudents: 0,
        lastMonthRevenue: 0,
        yearRevenue: 0
    });
    const access_token = localStorage.getItem('access_token');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const fetchDashboardInfo = async () => {
            const response = await GetDashboardInfo(access_token, consultant?.id);
            setProfile((prevState) => ({
                ...prevState,
                students: response.data.totalStudents,
                newStudents: response.data.monthlyNewStudents,
                lastMonthRevenue: response.data.financialReport.length > 0 ? response.data.financialReport.at(-1).value : 0,
                yearRevenue: response.data.revenue
            }));
        };
        fetchDashboardInfo();
    }, [access_token, consultant?.id]);

    useEffect(() => {
        if (location.state && location.state.changePassword) {
            setValue('3');
        }
        if (location.state && location.state.finance) {
            setValue('2');
        }
    }, [location.state]);

    if (!consultant) {
        signOut();
    }

    return (
        <div className="manage-accounts">
            <PageWrapper menuItem="/manage-account">
                <div className="content">
                    {imagePath && <Header
                        title="Minha Conta"
                        subtitle="Configure suas preferências abaixo"
                        name={profile.name}
                        occupation={profile.occupation}
                    />}

                    <div className="info">
                        <ProfileInfo
                            name={profile.name}
                            occupation={profile.occupation}
                            students={profile.students}
                            newStudents={profile.newStudents}
                            lastMonthRevenue={profile.lastMonthRevenue}
                            yearRevenue={profile.yearRevenue}
                            setImagePath={setImagePath}
                        />
                        <div>
                            <TabContext value={value}>
                                <Box>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Minha Conta"
                                    >
                                        <Tab label="Meus Dados" value="1" className="menu-tab"/>
                                        <Tab label="Financeiro" value="2" className="menu-tab"/>
                                        <Tab label="Senhas" value="3" className="menu-tab"/>
                                        {/* <Tab label="Planos" value="4" className="menu-tab"/> */}
                                    </Tabs>
                                </Box>
                                <TabPanel value="1"><MyData/></TabPanel>
                                <TabPanel value="2"><Finance/></TabPanel>
                                <TabPanel value="3"><Password
                                    showOldPassword={!location.state?.changePassword}/></TabPanel>
                                {/* <TabPanel value="4"><Plans/></TabPanel> */}
                            </TabContext>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
}

export default ManageAccount;