import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { CommonLoading } from 'react-loadingg';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import StudentHeader from '../../Components/StudentHeader/StudentHeader';
import DietInfo from './Components/DietInfo/DietInfo';
import MealsList from './Components/MealsList/MealsList';
import Header from '../../Components/Header/Header';
import ModalCreateMeal from './Components/ModalCreateMeal/ModalCreateMeal';
import ModalAddOption from '../ManageClientPlan/Components/ModalAddOption/ModalAddOption';

import { ListMealsDiet } from "../../Services/Diets/index";
import CaloriesCalculator from '../../Components/CaloriesCalculator/CaloriesCalculator';

import './DietDetails.scss';

const DietDetails = () => {
    const location = useLocation();
    const { diet, client, presetId } = location.state;
    const access_token = localStorage.getItem('access_token');

    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openCreateMeal, setOpenCreateMeal] = useState(false);
    const [openAddOption, setOpenAddOption] = useState(false);
    const [dietInfo, setDietInfo] = useState();
    const [nutritionalData, setNutritionalData] = useState(null);
    const contentRef = useRef(null);
    const [initialValues, setInitialValues] = useState({
        totalCalories: 0,
        protein: 0,
        carbohydrates: 0,
        lipids: 0
    });

    const loadStoredData = useCallback(() => {
        const key = client ? `nutritional_data_${client.id}` : 'nutritional_data_preset';
        const storedData = localStorage.getItem(key);
        if (storedData && dietInfo) {
            const data = JSON.parse(storedData);

            // Calcula os valores atuais apenas da dieta principal
            const currentValues = {
                totalCalories: meals.reduce((acc, meal) => acc + Number(meal.calories), 0),
                protein: meals.reduce((acc, meal) => acc + Number(meal.protein), 0),
                carbohydrates: meals.reduce((acc, meal) => acc + Number(meal.carbohydrates), 0),
                lipids: meals.reduce((acc, meal) => acc + Number(meal.lipids), 0)
            };

            // Calcula a diferença entre os valores atuais e iniciais
            const diff = {
                totalCalories: currentValues.totalCalories - initialValues.totalCalories,
                protein: currentValues.protein - initialValues.protein,
                carbohydrates: currentValues.carbohydrates - initialValues.carbohydrates,
                lipids: currentValues.lipids - initialValues.lipids
            };

            if (currentValues.totalCalories !== initialValues.totalCalories) {
                setNutritionalData({
                    totalCalories: data.totalCalories + diff.totalCalories,
                    protein: data.protein + diff.protein,
                    carbohydrates: data.carbohydrates + diff.carbohydrates,
                    lipids: data.lipids + diff.lipids
                });
            }
        }
    }, [dietInfo, client, meals, initialValues]);

    const fetchDietDetails = useCallback(async () => {
        try {
            const infoDiet = await ListMealsDiet(access_token, diet.id);

            // Salva os valores iniciais apenas da dieta principal
            const initialTotals = {
                totalCalories: infoDiet.data.food.reduce((acc, meal) => acc + Number(meal.calories), 0),
                protein: infoDiet.data.food.reduce((acc, meal) => acc + Number(meal.protein), 0),
                carbohydrates: infoDiet.data.food.reduce((acc, meal) => acc + Number(meal.carbohydrates), 0),
                lipids: infoDiet.data.food.reduce((acc, meal) => acc + Number(meal.lipids), 0)
            };

            setInitialValues(initialTotals);
            setMeals(infoDiet.data.food);
            setDietInfo({
                ...infoDiet.data,
                ...initialTotals
            });
            setLoading(false);
        } catch (error) {
            console.error('Erro ao buscar detalhes da dieta:', error);
            setLoading(false);
        }
    }, [access_token, diet.id]);

    useEffect(() => {
        fetchDietDetails();
    }, [fetchDietDetails]);

    useEffect(() => {
        loadStoredData();
    }, [loadStoredData]);

    useEffect(() => {
        const key = client ? `nutritional_data_${client.id}` : 'nutritional_data_preset';
        const storedData = localStorage.getItem(key);
        if (storedData) {
            const data = JSON.parse(storedData);
            setNutritionalData({
                totalCalories: data.totalCalories,
                protein: data.protein,
                carbohydrates: data.carbohydrates,
                lipids: data.lipids
            });
        }
    }, [client]);

    const handleOpenCreateMeal = () => {
        setOpenCreateMeal(true);
    };

    const handleCloseCreateMeal = () => {
        setOpenCreateMeal(false);
    };

    const handleOpenAddOption = () => {
        setOpenAddOption(true);
    };

    const handleCloseAddOption = async (added) => {
        setOpenAddOption(false);
        if (added) {
            await fetchDietDetails();

            setTimeout(() => {
                if (contentRef.current) {
                    contentRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }
            }, 100);
        }
    };

    const updateTotals = useCallback((newMeals) => {
        // Atualiza totais apenas da dieta principal
        const totalCalories = newMeals.reduce((acc, meal) => acc + Number(meal.calories), 0);
        const protein = newMeals.reduce((acc, meal) => acc + Number(meal.protein), 0);
        const carbohydrates = newMeals.reduce((acc, meal) => acc + Number(meal.carbohydrates), 0);
        const lipids = newMeals.reduce((acc, meal) => acc + Number(meal.lipids), 0);

        setDietInfo(prev => ({
            ...prev,
            totalCalories,
            protein,
            carbohydrates,
            lipids
        }));
    }, []);

    const handleSetMeals = useCallback((newMeals) => {
        setMeals(newMeals);
        updateTotals(newMeals);
    }, [updateTotals]);

    return (
        <div className="diet-details">
            <PageWrapper menuItem={client.presetId ? "/preset/diet" : "/manage-students"}>
                <div className="content" ref={contentRef}>
                    {!client.presetId &&
                        <StudentHeader client={client} tab="2" />
                    }
                    {client.presetId &&
                        <Header
                            title="SmartNutri"
                            subtitle="Crie sua biblioteca de dietas"
                            showReturn
                        />
                    }

                    <Stack className='stack-container' spacing={2}>
                        <DietInfo
                            dietId={diet.id}
                            name={diet.name}
                            dietStart={diet.dietStart}
                            dietEnd={diet.dietEnd}
                            clientId={client.id}
                            planActiveId={client.plan.id}
                            presetId={presetId}
                            nutritionalData={nutritionalData}
                        />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2
                        }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleOpenAddOption}
                            >
                                Adicionar opção de refeição
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenCreateMeal}
                            >
                                Criar alimento
                            </Button>
                        </Box>
                        {loading && (<CommonLoading color="#553872" size="large" />)}
                        {!loading && (
                            <>
                                <MealsList
                                    meals={meals}
                                    setMeals={handleSetMeals}
                                    diet={dietInfo}
                                    clientId={client.id}
                                    onUpdate={fetchDietDetails}
                                />
                            </>
                        )}
                    </Stack>
                </div>
                {/* <Evolution client={client} /> */}
                <CaloriesCalculator
                    client={client}
                    totalCalories={nutritionalData?.totalCalories || 0}
                    protein={nutritionalData?.protein || 0}
                    carbohydrates={nutritionalData?.carbohydrates || 0}
                    lipids={nutritionalData?.lipids || 0}
                    update={false}
                />
            </PageWrapper>

            <ModalCreateMeal
                open={openCreateMeal}
                handleClose={handleCloseCreateMeal}
            />

            <ModalAddOption
                modal={openAddOption}
                handleModal={handleCloseAddOption}
                diet={dietInfo}
            />
        </div>
    );
};

export default DietDetails;