import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Modal } from '@mui/material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../Components/Title/Title';

import { CreateDietOption } from "../../../../Services/Diets/index";

import './ModalAddOption.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome da opção é obrigatório!"),
});

const ModalAddOption = ({ modal, handleModal, diet }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const access_token = localStorage.getItem('access_token');

    const onSubmitHandler = async (data) => {
        try {
            const payload = {
                name: data.name,
                dietId: diet.id
            };

            await CreateDietOption(access_token, payload);
            handleModal(true);
        } catch (error) {
            console.error('Erro ao criar opção:', error);
        }
    };

    useEffect(() => {
        reset();
    }, [modal, reset]);

    return (
        <Modal
            open={modal}
            onClose={() => handleModal(false)}
            className='modal-plans'
            id="modal-plans"
        >
            <div className='modal-add-container'>
                <form className='modal-add'>
                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img
                                    src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-diet.svg"}
                                    alt="icon-modal-diet"
                                />
                            </div>
                            <div className='div-title'>
                                <Title title="Adicionar opção de refeição" />
                                <p>Insira o nome da opção abaixo</p>
                            </div>
                        </div>

                        <img
                            src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"}
                            alt="icon-close"
                            onClick={handleModal}
                        />
                    </div>

                    <div className='new-quiz'>
                        <div className='input'>
                            <TextFieldTrainMe
                                control={control}
                                name="name"
                                label="Nome da opção"
                                fullWidth
                                errors={errors.name}
                            />
                        </div>
                    </div>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <PrimaryButton text="Criar opção" onClick={handleSubmit(onSubmitHandler)} />
                    </Box>
                </form>
            </div>
        </Modal>
    );
};

export default ModalAddOption;