import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { matchSorter } from 'match-sorter';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
    FormControlLabel,
    Switch,
} from '@mui/material';

import Title from '../../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../../Components/TextField/TextField';
import TextArea from '../../../../../Components/TextArea/TextArea';
import CustomDatePicker from '../../../../../Components/CustomDatePicker/CustomDatePicker';
import PrimaryButton from '../../../../../Components/PrimaryButton/PrimaryButton';
import Select from '../../../../../Components/Select/Select';

import { CreateSchedule } from "../../../../../Services/Schedules/index";
import { GetStudents } from "../..//../../../Services/Students/index";
import { GetQuestionnares, SendQuestionnare } from "../../../../../Services/Questionnares";

import './ModalAddSchedule.scss';

const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório!").nullable(),
    description: yup.string().nullable(),
    ScheduleStart: yup.string().required("O campo data inicial é obrigatório!"),
    ScheduleEnd: yup.string()
        .required("O campo data final é obrigatório!")
});

const ModalAddSchedule = ({ open, handleModal, client }) => {
    const access_token = localStorage.getItem('access_token')
    const [, setOpenModal] = useState(open);
    const [students, setStudents] = useState([]);
    const [linkToStudent, setLinkToStudent] = useState(client ? true : false);
    const [linkToQuestionnaire, setLinkToQuestionnaire] = useState(false);
    const [questionnaires, setQuestionnaires] = useState([]);
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    // Criar data inicial e final com 1 hora de diferença
    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setHours(endDate.getHours() + 1);

    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            description: '',
            ScheduleStart: startDate,
            ScheduleEnd: endDate,
            student: null,
            questionnaire: null
        },
    });

    // Atualizar data final quando a inicial mudar
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'ScheduleStart') {
                const newEndDate = new Date(value.ScheduleStart);
                newEndDate.setHours(newEndDate.getHours() + 1);
                setValue('ScheduleEnd', newEndDate);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    useEffect(() => {
        // Se tiver client, setar o student no form
        if (client) {
            setValue('student', { id: client.studentId, label: client.name });
        }
    }, [client, setValue]);

    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label'] });

    const handleCloseModal = () => {
        setOpenModal(false);
        handleModal(true);
    };

    const handleLinkToStudentChange = (event) => {
        setLinkToStudent(event.target.checked);
        if (!event.target.checked) {
            setValue('student', null);
            setLinkToQuestionnaire(false);
            setValue('questionnaire', null);
        }
    };

    const handleLinkToQuestionnaireChange = (event) => {
        setLinkToQuestionnaire(event.target.checked);
        if (!event.target.checked) {
            setValue('questionnaire', null);
        }
    };

    const onSubmitHandler = async (data) => {
        try {
            const scheduleStartDate = new Date(data.ScheduleStart);
            const scheduleEndDate = new Date(data.ScheduleEnd);

            scheduleStartDate.setHours(scheduleStartDate.getHours() - 3);
            scheduleEndDate.setHours(scheduleEndDate.getHours() - 3);

            const createPayload = {
                name: data.name,
                description: linkToQuestionnaire ? 'Agendado para o questionário: ' + data.questionnaire.label : data.description || ' ',
                idStudent: linkToStudent ? (client ? client.studentId : data.student?.id) : null,
                ScheduleStart: scheduleStartDate.toISOString(),
                ScheduleEnd: scheduleEndDate.toISOString(),
            };

            // Criar o agendamento
            await CreateSchedule(access_token, createPayload);

            // Se tiver questionário selecionado, enviar para o aluno
            if (linkToQuestionnaire && data.questionnaire) {
                const studentId = client ? client.id : data.student?.userId;
                await SendQuestionnare(access_token, studentId, data.questionnaire.id);
            }

            handleCloseModal(true);
            reset();
        } catch (error) {
            console.error('Erro ao criar agendamento:', error);
            // Aqui você pode adicionar uma notificação de erro se desejar
        }
    };

    useEffect(() => {
        const getActiveStudents = async () => {
            const response = await GetStudents(access_token, 1, 500, "actives");
            const options = response.data.rows.map((r => ({ label: r.name, id: r.studentId, userId: r.id })));
            setStudents(options);
        };
        getActiveStudents();
    }, [access_token]);

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                const response = await GetQuestionnares(access_token, consultant.id);
                const options = response?.data?.rows.map(q => ({
                    id: q.id,
                    label: q.name
                })) || [];
                setQuestionnaires(options);
            } catch (error) {
                console.error('Erro ao buscar questionários:', error);
            }
        };
        fetchQuestionnaires();
    }, [access_token, consultant.id]);

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='form-add-schedule'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-bell.svg"} alt={"icon-bell"} />
                        </div>
                        <div className='div-title'>
                            <Title title={'Criar novo agendamento'} />
                            <p>Insira as informações abaixo </p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                        onClick={handleCloseModal} />
                </div>

                <div className='new-quiz'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            defaultValue=""
                            name={'name'}
                            label={'Insira o nome do agendamento'}
                            fullWidth
                            errors={errors.name}
                        />
                    </div>
                </div>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <CustomDatePicker
                        label={"Data Inicial"}
                        name={"ScheduleStart"}
                        control={control}
                        errors={errors.ScheduleStart}
                        inputFormat={"dd/MM/yyyy HH:mm"}
                        views={['day', 'hours', 'minutes']}
                    />
                    <CustomDatePicker
                        label={"Data Final"}
                        name={"ScheduleEnd"}
                        control={control}
                        errors={errors.ScheduleEnd}
                        inputFormat={"dd/MM/yyyy HH:mm"}
                        views={['day', 'hours', 'minutes']}
                    />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <Box sx={{ width: "50%" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={linkToStudent}
                                    onChange={handleLinkToStudentChange}
                                    color="primary"
                                    disabled={!!client}
                                />
                            }
                            label="Vincular aluno"
                        />
                    </Box>
                </Box>

                {linkToStudent && (
                    <>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                            <Box sx={{ width: "50%" }}>
                                {client ? (
                                    <TextFieldTrainMe
                                        control={control}
                                        name="studentName"
                                        label={client.name}
                                        fullWidth
                                        disabled
                                    />
                                ) : (
                                    <Select
                                        name="student"
                                        options={students}
                                        defaultValue={client ? client.name : ''}
                                        setValue={(data) => setValue('student', data)}
                                        filterOptions={filterOptions}
                                        label="Alunos ativos"
                                        control={control}
                                        errors={errors.student}
                                        disabled={!!client}
                                    />
                                )}
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row", gap: 5, mt: 2 }}>
                            <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={linkToQuestionnaire}
                                            onChange={handleLinkToQuestionnaireChange}
                                            color="primary"
                                            disabled={!watch('student') && !client}
                                        />
                                    }
                                    label="Vincular questionário"
                                />
                            </Box>
                        </Box>

                        {linkToQuestionnaire && (
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                <Box sx={{ width: "50%" }}>
                                    <Select
                                        name="questionnaire"
                                        options={questionnaires}
                                        setValue={(data) => setValue('questionnaire', data)}
                                        filterOptions={filterOptions}
                                        label="Questionários"
                                        control={control}
                                        errors={errors.questionnaire}
                                    />
                                </Box>
                            </Box>
                        )}
                    </>
                )}

                {!linkToQuestionnaire && (
                    <div className='new-quiz'>
                        <div className='textarea'>
                            <TextArea
                                control={control}
                                name={'description'}
                                label={'Insira sua descrição'}
                                fullWidth
                                errors={errors.description}
                            />
                        </div>
                    </div>
                )}

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PrimaryButton text="Criar agendamento" onClick={handleSubmit(onSubmitHandler)} />
                </Box>
            </div>
        </Modal>
    );
};

export default ModalAddSchedule;