import React, { useState } from 'react';
import { 
    Box, 
    FormControl,
    FormControlLabel, 
    Radio, 
    RadioGroup, 
    Stack, 
    Typography, 
    Alert, 
    AlertTitle,
    Paper,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/QrCode';
import LockIcon from '@mui/icons-material/Lock';

import PixPayment from '../PixPayment/PixPayment';
import CreditCardPayment from '../CreditCardPayment/CreditCardPayment';

const PaymentSelection = ({
    personalData,
    callback,
    consultantName,
    planInfo,
    consultantId,
    gender,
    plansActivityId,
    isMobile
}) => {
    const [method, setMethod] = useState('CARD');

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            p: 2
        }}>
            <Paper
                elevation={3}
                sx={{
                    width: { xl: '35%', lg: '50%', xs: '90%' },
                    p: 3,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Formas de Pagamento
                </Typography>

                {planInfo.recurring ? (
                    // Se for recorrente, mostra apenas cartão de crédito
                    <Box>
                        <Paper 
                            variant="outlined" 
                            sx={{ 
                                p: 2,
                                border: 2,
                                borderColor: 'primary.main',
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center">
                                <CreditCardIcon color="primary" />
                                <Typography>Cartão de Crédito</Typography>
                            </Stack>
                        </Paper>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, ml: 1 }}>
                            *Planos recorrentes aceitam apenas pagamento via cartão de crédito
                        </Typography>
                    </Box>
                ) : (
                    // Se não for recorrente, mostra todas as opções
                    <FormControl fullWidth>
                        <RadioGroup
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                        >
                            <Stack spacing={2}>
                                <Paper 
                                    variant="outlined" 
                                    sx={{ 
                                        p: 2,
                                        border: method === 'CARD' ? 2 : 1,
                                        borderColor: method === 'CARD' ? 'primary.main' : 'grey.300',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setMethod('CARD')}
                                >
                                    <FormControlLabel 
                                        value="CARD" 
                                        control={<Radio />} 
                                        label={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <CreditCardIcon color={method === 'CARD' ? 'primary' : 'action'} />
                                                <Typography>Cartão de Crédito</Typography>
                                            </Stack>
                                        }
                                    />
                                </Paper>

                                <Paper 
                                    variant="outlined" 
                                    sx={{ 
                                        p: 2,
                                        border: method === 'PIX' ? 2 : 1,
                                        borderColor: method === 'PIX' ? 'primary.main' : 'grey.300',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setMethod('PIX')}
                                >
                                    <FormControlLabel 
                                        value="PIX" 
                                        control={<Radio />} 
                                        label={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <PixIcon color={method === 'PIX' ? 'primary' : 'action'} />
                                                <Typography>PIX</Typography>
                                            </Stack>
                                        }
                                    />
                                </Paper>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                )}

                {/* Formulário de Pagamento */}
                <Box sx={{ mt: 3 }}>
                    {!planInfo.recurring && method === 'PIX' && (
                        <PixPayment
                            personalData={personalData}
                            consultantName={consultantName}
                            planInfo={planInfo}
                            consultantId={consultantId}
                            gender={gender}
                            isMobile={isMobile}
                            callback={callback}
                            plansActivityId={plansActivityId}
                        />
                    )}

                    {(method === 'CARD' || planInfo.recurring) && (
                        <CreditCardPayment
                            personalData={personalData}
                            planInfo={planInfo}
                            plansActivityId={plansActivityId}
                            callback={callback}
                            consultantId={consultantId}
                        />
                    )}
                </Box>

                {planInfo.recurring && (
                    <Alert 
                        severity="warning" 
                        icon={<LockIcon />}
                        sx={{ 
                            mt: 3,
                            '& .MuiAlert-icon': {
                                color: 'warning.main'
                            }
                        }}
                    >
                        <AlertTitle>Pagamento Recorrente</AlertTitle>
                        Este é um plano com pagamento recorrente. Você será cobrado automaticamente.
                    </Alert>
                )}

                {/* Segurança */}
                <Stack 
                    direction="row" 
                    spacing={1} 
                    alignItems="center" 
                    justifyContent="center"
                    sx={{ mt: 3, color: 'text.secondary' }}
                >
                    <LockIcon fontSize="small" />
                    <Typography variant="body2">
                        Pagamento 100% seguro
                    </Typography>
                </Stack>
            </Paper>
        </Box>
    );
};

export default PaymentSelection;