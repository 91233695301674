import { GetAsync, PostAsync } from '../api';

/**
 * Método responsável por obter dados do pagamento por pix
 */
const PostPix = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync(`/payments/student/pix`, {}, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por enviar pagamento de cartão de crédito
 */
const CreatePayment = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync(`/payments/student/credit-card`, {}, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por enviar pagamento de cartão de crédito recorrente  
 */
const CreateRecurringPayment = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync(`/payments/student/recurring`, {}, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável enviar pre aprovação da recorrência
 */
const PostPreApproval = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PostAsync(`/payments/consultant/pre-approval`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por validar se o pagamento foi efetuado
 */
const GetPayment = (id, consultantId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await GetAsync(`/payments/find/${id}/${consultantId}`, {}, {})
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por as transações do consultor
 */
const GetTransactions = (page, pageSize) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/payments?page=${page}&pageSize=${pageSize}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};


export {
    PostPix,
    GetPayment,
    CreatePayment,
    PostPreApproval,
    GetTransactions,
    CreateRecurringPayment
}