import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { ptBR } from 'date-fns/locale';

import './CustomDatePicker.scss';

const CustomDatePicker = ({ name, label, errors, control, inputFormat = "dd/MM/yyyy", views = ['day'] }) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <DatePicker
                            views={views}
                            inputFormat={inputFormat}
                            label={label}
                            value={value || null}
                            onChange={(newValue) => {
                                onChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    helperText={null}
                                    fullWidth
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
            {errors && <p className='error'>{errors.message}</p>}
        </>
    );
}

export default CustomDatePicker;