import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, FormControl, InputLabel, MenuItem, Modal, Select as MuiSelect } from '@mui/material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../Components/Title/Title';

import { CreateLibraryMeal, GetLibrary } from "../../../../Services/Meals/index";

import './ModalCreateMeal.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo de nome do alimento é obrigatório."),
    calories: yup.number().required("O campo de calorias é obrigatório."),
    protein: yup.number().required("O campo de proteina é obrigatório."),
    carbohydrates: yup.number().required("O campo de carboidratos é obrigatório."),
    lipids: yup.number().required("O campo de lipidos é obrigatório."),
    fiber: yup.number().required("O campo de fibra é obrigatório."),
});

const ModalCreateMeal = ({
    open,
    handleClose,
}) => {
    const options = useMemo(() => ['GRAMAS', 'UNIDADE(S)', 'PEDAÇO(S)', 'FATIA(S)', 'MILILITROS'], []);
    const { control, handleSubmit, setValue, setError, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            calories: 0,
            protein: 0,
            carbohydrates: 0,
            lipids: 0,
            fiber: 0,
            comparativeBase: 100
        }
    });
    const access_token = localStorage.getItem('access_token');
    const foodsLibrary = JSON.parse(sessionStorage.getItem('foodsLibrary'));
    const [weightType, setWeightType] = useState(options[0]);

    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-add-meal-container') {
            handleClose();
        }
    };

    const getLibrary = async () => {
        const foods = await GetLibrary(access_token);
        const library = foods.data.map(f => ({ ...f, label: f.name }))
        sessionStorage.setItem('foodsLibrary', JSON.stringify(library));
    };

    const onSubmitHandler = async (payload) => {
        const food = foodsLibrary.find(food => food.name === payload.name);
        if (food) {
            setError('name', { type: 'custom', message: 'Alimento já cadastrado na biblioteca.' });
            return;
        }

        const requestPayload = [{
            name: payload.name,
            comparativeBase: payload.comparativeBase,
            typeComparativeBase: weightType,
            calories: payload.calories,
            protein: payload.protein,
            carbohydrates: payload.carbohydrates,
            lipids: payload.lipids,
            fiber: payload.fiber
        }];
        await CreateLibraryMeal(requestPayload);
        await getLibrary();
        reset();
        setWeightType(options[0]);
        handleClose();
    };

    useEffect(() => {
        reset();
        setWeightType(options[0]);
    }, [reset, options]);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-create-meal"
            aria-describedby="modal-create-new-meal"
        >
            <div className='modal-create-meal-container' onClick={e => verifyElementClicked(e)} id='modal-add-create-container'>
                <form className='modal-add'>
                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"}
                                    alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Criar Alimento" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleClose} />
                    </div>

                    <div className="row">
                        <div className='new-quiz full-width'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={''}
                                    name="name"
                                    label="Nome do alimento"
                                    fullWidth
                                    errors={errors.name}
                                />
                            </div>
                        </div>

                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={0}
                                    name="calories"
                                    label="Calorias"
                                    type="number"
                                    fullWidth
                                    errors={errors.calories}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={0}
                                    name="protein"
                                    label="Proteina (g)"
                                    type="number"
                                    fullWidth
                                    errors={errors.protein}
                                />
                            </div>
                        </div>

                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={0}
                                    name="carbohydrates"
                                    label="Carboidratos (g)"
                                    type="number"
                                    fullWidth
                                    errors={errors.carbohydrates}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={0}
                                    name="lipids"
                                    label="Lipidios (g)"
                                    type="number"
                                    fullWidth
                                    errors={errors.lipids}
                                />
                            </div>
                        </div>

                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={0}
                                    name="fiber"
                                    label="Fibra (g)"
                                    type="number"
                                    fullWidth
                                    errors={errors.fiber}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='new-quiz full-width'>
                            <div className='select'>
                                <Box>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Selecione a unidade de medida</InputLabel>
                                        <MuiSelect
                                            value={weightType}
                                            onChange={(e) => {
                                                setValue('weightType', e.target.value);
                                                setWeightType(e.target.value);
                                                setValue('comparativeBase', e.target.value === 'GRAMAS' || e.target.value === 'MILILITROS' ? 100 : 1);
                                            }}
                                            label="Selecione a unidade de medida"
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MuiSelect>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>


                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue={100}
                                    name="comparativeBase"
                                    label="Base de comparação (gramas ou unidades)"
                                    type="number"
                                    fullWidth
                                    errors={errors.comparativeBase}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="button">
                        <PrimaryButton
                            text={'Criar Alimento'}
                            onClick={handleSubmit(onSubmitHandler)}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ModalCreateMeal;