import React, {useEffect, useState} from 'react';
import {Autocomplete, Modal, TextField} from '@mui/material';

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import {GetQuestionnares, SendQuestionnare} from "../../../../Services/Questionnares/index";

import './ModalQuiz.scss';

const ModalQuiz = ({modal, setModal, student, handleModal}) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const [selectedQstn, setSelectedQstn] = useState();
    const access_token = localStorage.getItem('access_token');
    const [questionnaires, setQuestionnaires] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            const response = await GetQuestionnares(access_token, consultant.id);
            const options = response.data?.rows.map((q) => {
                return {label: q.name, id: q.id};
            });
            setQuestionnaires(options);
            setSelectedQstn(options[0]);
        };

        fetchData();
    }, [access_token, consultant.id]);

    const sendQuestionnare = async () => {
        await SendQuestionnare(access_token, student.id, selectedQstn.id);
        setModal(!modal);
        handleModal(student.studentId);
    };

    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-container') {
            setModal(!modal);
        }
    };

    return (
        <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="Adicionar questionario"
            aria-describedby="Adicionar questionario"
        >
            <div className='modal-container' onClick={e => verifyElementClicked(e)} id='modal-container'>
                <div className='modal'>
                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-quiz.svg"}
                                     alt={"icon-modal-quiz"}/>
                            </div>
                            <div className='div-title'>
                                <Title title="Questionário"/>
                                <Subtitle subtitle="Enviar questionário para o aluno"/>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                             onClick={() => setModal(!modal)}/>
                    </div>

                    <div className='new-quiz'>
                        <div className='div-title'>
                            <p>Deseja enviar um novo questionário para o aluno?</p>
                        </div>

                        <div className='select'>
                            <Autocomplete
                                name="questionnaire"
                                options={questionnaires}
                                value={selectedQstn}
                                onChange={(_, data) => {
                                    setSelectedQstn(data);
                                }}
                                renderInput={(params) => <TextField {...params} label={'Selecione o questionário'}/>}
                            />
                        </div>
                    </div>

                    <div className='button'>
                        <PrimaryButton text="Enviar" onClick={sendQuestionnare}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalQuiz;