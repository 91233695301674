/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Box, Checkbox, FormControlLabel, FormGroup, Modal, Typography } from '@mui/material';

import Title from '../../../../Components/Title/Title';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Select from '../../../../Components/Select/Select';

import { GetPresets, CreatePresetsPlan } from "../../../../Services/Presets/index";

import './ModalAddPreset.scss';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import { Link } from 'react-router-dom';

const ModalAddPreset = ({ modal, handleModal, client, type }) => {
    const { control, handleSubmit, formState: { errors }, } = useForm({});

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preset, setPreset] = useState(null);
    const [presetsOptions, setPresetsOptions] = useState([]);
    const [weeks, setWeeks] = useState([]);

    const onSelectWeek = (week, checked) => {
        setWeeks((weeks) => {
            return weeks.map((w, i) => {
                if (w === week) return { ...w, checked: Boolean(checked) };
                return w;
            });
        });
    };

    const handleCloseModal = (updated) => {
        handleModal(updated);
    };

    const onSubmitHandler = async () => {
        setLoading(true);

        const selectedWeeks = weeks.filter((w) => w.checked);
        if ((!selectedWeeks || selectedWeeks.length === 0)) {
            setShowError(true);
            setLoading(false);
            return;
        }
        let createPayload = {
            weeks: selectedWeeks.map((w) => (w.value)),
            plansActivity: client.plan.id,
            type
        };
        await CreatePresetsPlan(createPayload, type, preset.id);

        handleCloseModal(true);
        setLoading(false);
    };

    const registerWeeks = () => {
        const weeksElem = [];
        for (let i = 0; i < client.plan.weeks; i++) {
            weeksElem.push({
                label: i + 1,
                value: i + 1,
                checked: true
            });
        }
        setWeeks(weeksElem);
    };

    useEffect(() => {
        const fetchPresets = async () => {
            const response = await GetPresets(type);
            const options = response?.data?.rows.map((value) => ({
                id: value.id,
                label: value.name
            }));
            setPresetsOptions(options);
            setPreset(options[0]);
        }
        fetchPresets();
    }, [type]);

    useEffect(() => {
        registerWeeks();

        if (!modal) {
            setLoading(false);
            setShowError(false);
        }
    }, [modal]);


    return (
        <Modal
            open={modal}
            onClose={() => handleModal(false)}
            className='modal-preset'
            id="modal-preset"
        >
            <div className='modal-add-preset'>
                <form className='modal-add' id="form" onSubmit={handleSubmit(onSubmitHandler)}>

                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-training.svg"}
                                    alt={"icon-modal-training"} />
                            </div>
                            <div className='div-title'>
                                <Title title={'Adicionar Modelo'} />
                                <p>Selecione o treino pré configurado</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                            onClick={() => handleCloseModal(false)} />
                    </div>
                    {presetsOptions && presetsOptions.length > 0 && <>
                        <Select
                            disableClearable
                            name="group"
                            options={presetsOptions}
                            label="Selecione o modelo"
                            setValue={setPreset}
                            value={preset}
                            control={control}
                            errors={errors.group}
                        />
                        {
                            type === 'training' &&
                            <div className="new-quiz">
                                <Subtitle subtitle="Semana(s):" />
                                <FormGroup className="weeks-form-group">
                                    {weeks.map((week, i) => (
                                        <FormControlLabel style={{ margin: 0 }} key={week.value} control={
                                            <Checkbox
                                                name={week.label.toString()}
                                                value={week.value}
                                                id={week.value.toString()}
                                                checked={week.checked}
                                                onChange={(e) => onSelectWeek(week, !week.checked)}
                                            />
                                        } label={week.label} />
                                    ))}
                                </FormGroup>
                                {showError && <p className='error'>Selecione pelo menos uma semana.</p>}
                            </div>
                        }
                        <Box className='button' sx={{ display: 'flex', justifyContent: 'center' }}>
                            <PrimaryButton text="Adicionar" loading={loading} />
                        </Box>
                    </>}

                    {(!presetsOptions || presetsOptions.length === 0) && (
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 5, mx: 3, mt: 1, py: 3 }}>
                            <Typography>{type === 'training' ? 'Você ainda não criou modelos de treino' : 'Você ainda não criou modelos de dieta'}, &nbsp;
                                <Link to={`/preset/${type}`} variant="body2">clique aqui para criar.</Link>
                            </Typography>
                        </Box>

                    )}
                </form>
            </div>
        </Modal>
    );
};

export default ModalAddPreset;