import React, { useEffect, useMemo, useState } from "react";
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import IntlCurrencyInput from "react-intl-currency-input"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";
import TextFieldTrainMe from "../../../../Components/TextField/TextField";
import Feedback from "../../../../Components/Feedback/Feedback";
import TextArea from "../../../../Components/TextArea/TextArea";

import { CreatePlans, UpdatePlans } from '../../../../Services/Plans/index';
import { GetQuestionnares } from "../../../../Services/Questionnares/index";

import "./PlanForm.scss";

const schema = yup.object().shape({
    name: yup.string().required("O campo nome é obrigatório!"),
    price: yup.string().required("O campo preço é obrigatório!"),
    description: yup.string().required("O campo descrição é obrigatório!"),
});

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

const PlanForm = ({ plan }) => {
    const { id } = JSON.parse(sessionStorage.getItem('consultant'));
    const quizId = plan?.quiz?.id;
    const access_token = localStorage.getItem('access_token');
    const navigate = useNavigate();

    const [questionnaires, setQuestionnaires] = useState([]);
    const [feedback, setFeedback] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [selectedOption, setSelectedOption] = useState(quizId);
    const [showError, setShowError] = useState(false);
    const [showPriceError, setShowPriceError] = useState(false);
    const [training, setTraining] = useState(true);
    const [diet, setDiet] = useState(true);
    const [inLoco, setInLoco] = useState(false);
    const [recurring, setRecurring] = useState(false);

    const periodData = useMemo(() => [
        { label: "Mensal", id: 4 },
        { label: "Bimestral", id: 9 },
        { label: "Trimestral", id: 13 },
        { label: "Quadrimestral", id: 17 },
        { label: "Pentamestral", id: 21 },
        { label: "Semestral", id: 26 },
        { label: "Anual", id: 51 }
    ], []);
    const [period, setPeriod] = useState(periodData[0]);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            id: plan?.id,
            name: plan?.name,
            price: plan?.price,
            weeks: plan?.weeks,
            description: plan?.description,
            available: true,
            recurring: false,
            quiz: plan?.quiz?.id
        }
    });

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setValue('quiz', event.target.value);
    }

    const updatePlan = async (data) => {
        const dataUpdate = {
            id: data.id,
            name: data.name,
            price: Number(data.price),
            weeks: period.id,
            description: data.description,
            available: true,
            training: training,
            diet: diet,
            inLoco: inLoco,
            recurring: recurring,
            quiz: selectedOption || null
        };
        await UpdatePlans(access_token, dataUpdate);
    };

    const insertPlan = async (data) => {
        const dataInsert = {
            name: data.name,
            price: Number(data.price),
            weeks: period.id,
            description: data.description,
            available: true,
            training: training,
            diet: diet,
            inLoco: inLoco,
            recurring: recurring,
            quiz: selectedOption || null
        };
        await CreatePlans(access_token, dataInsert);
    }

    const savePlan = async (data, e) => {
        try {
            if (!data.quiz) {
                setShowError(true);
                return;
            }
            data.available = true;
            data.price = data.price.replace("R$ ", "").replace(",", ".");
            if (data.price < 5) {
                setShowPriceError(true);
                return;
            }

            if (data.id) {
                await updatePlan(data);
            } else {
                await insertPlan(data);
            }

            setFeedback(true);
            setMessage('Nova consultoria salva com sucesso');
            setSeverity('success');
            navigate(-1);
        } catch {
            setFeedback(true);
            setMessage('Não foi possivel salvar a consultoria.');
            setSeverity('error');
        }
    };

    const handlePriceChange = (event, value, maskedValue) => {
        setValue('price', value);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            let questionnaireList = await GetQuestionnares(access_token, id);
            questionnaireList = questionnaireList?.data?.rows;
            setQuestionnaires(questionnaireList || []);
        };
        fetchData();
    }, [access_token, id]);

    useEffect(() => {
        if (plan) {
            setValue('id', plan?.id);
            setValue('name', plan?.name);
            setValue('weeks', plan?.weeks);
            setValue('description', plan?.description);
            setValue('available', true);
            setValue('quiz', plan?.quiz?.id);
            setSelectedOption(plan?.quiz?.id);
            setTraining(plan?.training);
            setRecurring(plan?.recurring);
            setDiet(plan?.diet);
            setInLoco(plan?.inLoco);
            setPeriod(periodData.find(p => p.id === plan?.weeks));
        }

    }, [plan, setValue, periodData]);

    useEffect(() => {
        if (feedback) {
            setTimeout(() => {
                setFeedback(false);
                setMessage('');
                setShowError(false);
            }, 4500);
        }
    }, [feedback]);

    useEffect(() => {
        if (errors && errors.quiz) {
            setTimeout(() => {
                setShowError(false);
            }, 2500);
        }
    }, [errors, errors.quiz]);

    return (
        <div className="plan-form">
            <div className="data">
                {feedback && (
                    <Feedback severity={severity} text={message} />
                )}

                <form className="form" id="form">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        padding: 3,
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                        {/* Seção de Informações Básicas */}
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>Informações Básicas</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ flex: 2 }}>
                                    <TextFieldTrainMe
                                        control={control}
                                        name="name"
                                        label="Nome da Consultoria"
                                        placeholder="Ex: Plano de Emagrecimento Premium"
                                        fullWidth
                                        errors={errors.name}
                                    />
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1
                                }}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Pagamento
                                    </Typography>
                                    <div className="price-container">
                                        <IntlCurrencyInput
                                            currency="BRL"
                                            config={currencyConfig}
                                            onChange={handlePriceChange}
                                            defaultValue={Number(plan?.price)}
                                        />
                                    </div>
                                    {showPriceError && (
                                        <Typography color="error" variant="caption">
                                            O valor mínimo é de R$ 5,00
                                        </Typography>
                                    )}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={recurring}
                                                onChange={() => setRecurring(!recurring)}
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                Pagamento Recorrente
                                            </Typography>
                                        }
                                    />
                                    {recurring && (
                                        <Alert
                                            severity="info"
                                            sx={{
                                                '& .MuiAlert-message': {
                                                    fontSize: '0.875rem'
                                                }
                                            }}
                                        >
                                            O aluno será cobrado automaticamente {period?.label?.toLowerCase()}mente, mantendo o mesmo valor de R$ {plan?.price || '0,00'}
                                        </Alert>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        {/* Seção de Duração e Questionário */}
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>Duração e Avaliação</Typography>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Autocomplete
                                        disableClearable
                                        options={periodData}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Duração do Plano" />
                                        )}
                                        value={period}
                                        onChange={(_, data) => setPeriod(data)}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>Questionário de Avaliação</InputLabel>
                                        <Select
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            label="Questionário de Avaliação"
                                        >
                                            {questionnaires.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {showError && (
                                        <Typography color="error" variant="caption">
                                            Selecione um questionário
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        {/* Seção de Descrição */}
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>Descrição do Plano</Typography>
                            <TextArea
                                control={control}
                                name="description"
                                label="Descreva os benefícios e características do plano"
                                placeholder="Ex: Plano personalizado com foco em emagrecimento, incluindo dieta e treinos adaptados às suas necessidades..."
                                fullWidth
                                multiline
                                rows={4}
                                errors={errors.description}
                            />
                        </Box>

                        {/* Seção de Características */}
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2 }}>Características do Plano</Typography>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                                gap: 2
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={training}
                                            onChange={() => setTraining(!training)}
                                            color="primary"
                                        />
                                    }
                                    label="Treinos"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={diet}
                                            onChange={() => setDiet(!diet)}
                                            color="primary"
                                        />
                                    }
                                    label="Dieta"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={inLoco}
                                            onChange={() => setInLoco(!inLoco)}
                                            color="primary"
                                        />
                                    }
                                    label="Treino Presencial"
                                />
                            </Box>
                        </Box>

                        {/* Botão de Salvar */}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            borderTop: '1px solid #eee',
                            paddingTop: 3
                        }}>
                            <PrimaryButton
                                text="Salvar Consultoria"
                                onClick={handleSubmit(savePlan)}
                            />
                        </Box>
                    </Box>
                </form>
            </div>
        </div>
    );
};

export default PlanForm;
