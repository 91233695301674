import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';
import { ArrowBackOutlined } from '@mui/icons-material';

import './Header.scss';

const Header = ({ title, subtitle, showReturn = false, showInfo = true }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={{ xs: 12 }}>
                    {showReturn && (
                        <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackOutlined />}>
                            Voltar
                        </Button>
                    )}
                </Grid>
                <Grid container size={{ xs: 12, md: 9 }}>
                    <Grid size={{ xs: 8, md: 8 }}>
                        <Title title={title} />
                        <Subtitle subtitle={subtitle} />
                    </Grid>
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{ display: { xs: 'none', sm: 'block' }, alignItems: "flex-end" }}>
                    <Grid container display="flex">
                        <Grid size={{ xs: 2 }}>
                            <Avatar
                                alt={consultant.name}
                                src={consultant?.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${consultant.profileImagePath}?${new Date().getMilliseconds()}`
                                    : "https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg"} />
                        </Grid>
                        <Grid size={{ xs: 10 }}>
                            <strong>{consultant?.name}</strong> <br></br>
                            <span>{consultant?.occupation}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Header;