import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    CardActionArea,
    Typography,
    Grid2 as Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    LinearProgress,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CampaignIcon from '@mui/icons-material/Campaign';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import QuizIcon from '@mui/icons-material/Quiz';
import BarChartIcon from '@mui/icons-material/BarChart';
import LanguageIcon from '@mui/icons-material/Language';
import { FinishOnboarding } from '../../Services/Consultants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WelcomeDialog = ({ open, onClose }) => (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="welcome-dialog-description"
        PaperProps={{
            sx: {
                borderRadius: 2,
                maxWidth: 500
            }
        }}
    >
        <DialogTitle sx={{ m: 0, p: 2, pb: 0 }}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 4, textAlign: 'center' }}>
            <CheckCircleOutlineIcon
                sx={{
                    fontSize: 60,
                    color: '#28a745',
                    mb: 2
                }}
            />
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: '#662d91',
                    fontWeight: 600,
                    mb: 2
                }}
            >
                Conta configurada com sucesso!
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary"
                paragraph
            >
                Agora vamos te guiar pela plataforma para você conhecer todas as funcionalidades
                e começar a gerenciar seus alunos da melhor forma.
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    backgroundColor: 'rgba(102, 45, 145, 0.05)',
                    padding: 2,
                    borderRadius: 1,
                    border: '1px solid rgba(102, 45, 145, 0.1)',
                    mt: 2
                }}
            >
                Faça o tour pela plataforma para conhecer algumas de nossas funcionalidades.
            </Typography>
            <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
                Começar
            </Button>
        </DialogContent>
    </Dialog>
);
const TutorialCard = ({ card, isCompleted, isNext, onClick }) => (
    <Card
        sx={{
            width: '100%',
            height: '100%',
            transition: 'all 0.3s ease',
            position: 'relative',
            opacity: !isCompleted && !isNext ? 0.6 : 1,
            transform: isNext ? 'scale(1.05)' : 'scale(1)',
            '&:hover': isNext && {
                transform: 'scale(1.08)',
                boxShadow: 6,
            }
        }}
    >
        {isNext && (
            <Box
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    backgroundColor: '#662d91',
                    color: 'white',
                    padding: '4px 12px',
                    borderRadius: '12px',
                    fontSize: '0.75rem',
                    fontWeight: 'bold',
                    zIndex: 1,
                }}
            >
                Próximo passo
            </Box>
        )}
        <CardActionArea
            onClick={onClick}
            sx={{ height: '100%', cursor: isNext ? 'pointer' : 'default' }}
            disabled={!isNext}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: isCompleted ? 'row' : 'column',
                    alignItems: 'center',
                    gap: 2,
                    padding: 4,
                    backgroundColor: isNext ? 'rgba(102, 45, 145, 0.05)' : 'transparent',
                }}
            >
                {isCompleted ? (
                    <>
                        <CheckCircleIcon sx={{ color: '#28a745', fontSize: 24 }} />
                        <Typography variant="body1">{card.title}</Typography>
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                backgroundColor: '#662d91',
                                borderRadius: '50%',
                                padding: 2,
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                transition: 'all 0.3s ease',
                            }}
                        >
                            {card.icon}
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{
                                    fontWeight: 500,
                                    mb: 1
                                }}
                            >
                                {card.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    opacity: isNext ? 1 : 0.7,
                                    px: 2
                                }}
                            >
                                {card.description}
                            </Typography>
                        </Box>
                        {isNext && (
                            <Button
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#662d91',
                                    '&:hover': {
                                        backgroundColor: '#553872',
                                    }
                                }}
                            >
                                Começar
                            </Button>
                        )}
                    </>
                )}
            </CardContent>
        </CardActionArea>
    </Card>
);

const FeatureCard = ({ feature, onClick }) => (
    <Box sx={{
        position: 'relative',
        height: { xs: '50vh', sm: '70vh' },
        width: '100%',
        borderRadius: 2,
        overflow: 'hidden'
    }}>
        <Box
            component="img"
            src={feature.image}
            alt={feature.title}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
            }}
        />
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'linear-gradient(to top, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%)',
                padding: { xs: 2, sm: 4 },
                color: 'white',
                textAlign: 'center'
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    fontSize: { xs: '1.5rem', sm: '2.125rem' },
                    fontWeight: 600,
                    mb: { xs: 1, sm: 2 }
                }}
            >
                {feature.title}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    mb: { xs: 2, sm: 3 },
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    px: { xs: 1, sm: 4 },
                    maxWidth: '800px',
                    margin: '0 auto'
                }}
            >
                {feature.description}
            </Typography>
            <Button
                variant="contained"
                onClick={() => onClick(feature)}
                endIcon={<ArrowForwardIcon />}
                sx={{
                    backgroundColor: '#662d91',
                    '&:hover': {
                        backgroundColor: '#553872',
                    },
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    py: { xs: 1, sm: 1.5 },
                    px: { xs: 3, sm: 4 }
                }}
            >
                Explorar
            </Button>
        </Box>
    </Box>
);

const cards = [
    {
        id: 1,
        title: 'Cadastrar Aluno',
        description: 'Comece adicionando seu primeiro aluno à plataforma para gerenciar seus treinos e dietas.',
        icon: <PersonAddIcon sx={{ fontSize: 40 }} />,
        path: '/manage-students',
        query: '?tutorial=true',
        image: 'https://storage.googleapis.com/trainme-assets/onboarding/cadastrar-aluno.webp'
    },
    {
        id: 2,
        title: 'Cadastrar Modelos',
        description: 'Crie seu primeiro treino modelo usando nossa ferramenta de periodização.',
        icon: <CalendarMonthIcon sx={{ fontSize: 40 }} />,
        path: '/preset/training',
        query: '?tutorial=true',
        image: 'https://storage.googleapis.com/trainme-assets/onboarding/cadastrar-modelos.webp'
    },
    {
        id: 3,
        title: 'Prescrever protocolo',
        description: 'Desenvolva protocolos específicos para cada aluno baseado em seus objetivos.',
        icon: <AssignmentIcon sx={{ fontSize: 40 }} />,
        path: '/manage-students',
        query: '?tutorial=true',
        image: 'https://storage.googleapis.com/trainme-assets/onboarding/prescrever-protocolo.webp'
    },
    {
        id: 4,
        title: 'Divulgar meu trabalho',
        description: 'Aprenda a usar nossas ferramentas de vendas para expandir sua base de clientes.',
        icon: <CampaignIcon sx={{ fontSize: 40 }} />,
        path: '/plans',
        query: '?tutorial=true',
        image: 'https://storage.googleapis.com/trainme-assets/onboarding/divulgar-trabalho.webp'
    }
];

const OnboardingSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentStep = location.state?.step;
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const additionalFeatures = [
        {
            id: 1,
            title: 'Vendas',
            description: 'Tão simples quanto copiar e colar o link de pagamento para seus alunos fazerem o pagamento.',
            icon: <BarChartIcon sx={{ fontSize: 40 }} />,
            path: '/plans',
            image: process.env.REACT_APP_PUBLIC_URL + 'link-pagamento.png'
        },
        {
            id: 2,
            title: 'Gestão Financeira',
            description: 'Acompanhe seus ganhos e gerencie seus recebimentos em um só lugar.',
            icon: <AccountBalanceWalletIcon sx={{ fontSize: 40 }} />,
            path: '/wallet',
            image: process.env.REACT_APP_PUBLIC_URL + 'carteira.png'
        },
        {
            id: 3,
            title: 'Receba alunos sem esforço',
            description: 'Ao completar seu perfil, você receberá alunos potenciais para contratar seu serviço sem esforço.',
            icon: <LanguageIcon sx={{ fontSize: 40 }} />,
            path: `https://consultores.trainme.com.br/`,
            image: process.env.REACT_APP_PUBLIC_URL + 'mktplace.png'
        },
        {
            id: 4,
            title: 'Questionários Personalizados',
            description: 'Crie questionários específicos para entender melhor as necessidades dos seus alunos.',
            icon: <QuizIcon sx={{ fontSize: 40 }} />,
            path: '/questionnaire',
            image: process.env.REACT_APP_PUBLIC_URL + 'questionario.png'
        },
        {
            id: 5,
            title: 'Modelos customizáveis',
            description: 'Crie modelos de treino e dietas personalizados e crie protocolos em segundos.',
            icon: <BarChartIcon sx={{ fontSize: 40 }} />,
            path: '/preset/training',
            image: process.env.REACT_APP_PUBLIC_URL + 'modelos.png'
        },
        {
            id: 6,
            title: 'Acompanhamento de alunos',
            description: 'Acompanhe o progresso de seus alunos e envie feedbacks para eles.',
            icon: <BarChartIcon sx={{ fontSize: 40 }} />,
            path: '/manage-students',
            image: process.env.REACT_APP_PUBLIC_URL + 'alunos.png'
        },
        {
            id: 7,
            title: 'Sua página profissional',
            description: 'Crie sua página profissional e compartilhe seu trabalho com o mundo.',
            icon: <BarChartIcon sx={{ fontSize: 40 }} />,
            path: `https://consultores.trainme.com.br/consultor/${consultant.id}`,
            image: process.env.REACT_APP_PUBLIC_URL + 'marketplace.png'
        }
    ];

    const [completedSteps, setCompletedSteps] = useState(() => {
        return JSON.parse(localStorage.getItem('completedTutorialSteps') || '[]');
    });
    const [openWelcome, setOpenWelcome] = useState(() => {
        return !localStorage.getItem('welcomeMessageShown');
    });

    const handleCloseWelcome = () => {
        setOpenWelcome(false);
        // GTM - Close Welcome Message
        window.dataLayer.push({
            'event': 'close_onboarding_welcome',
            'consultant_id': consultant?.id,
            'time_visible': Date.now() - localStorage.getItem('welcomeMessageShownTime')
        });
    };

    const handleCardClick = (card) => {
        // GTM - Tutorial Step Start
        window.dataLayer.push({
            'event': 'tutorial_step_start',
            'step_id': card.id,
            'step_name': card.title,
            'consultant_id': consultant?.id
        });

        navigate(card.path + card.query, {
            state: {
                returnTo: '/onboarding-success',
                stepId: card.id
            }
        });
    };

    useEffect(() => {
        if (openWelcome) {
            // Marca que a mensagem já foi mostrada
            localStorage.setItem('welcomeMessageShown', 'true');

            // GTM - View Welcome Message
            window.dataLayer.push({
                'event': 'view_onboarding_welcome',
                'consultant_id': consultant?.id,
                'is_first_view': true
            });
        }
    }, [openWelcome, consultant?.id]);

    // Efeito para registrar o tempo quando a mensagem é mostrada
    useEffect(() => {
        if (openWelcome) {
            localStorage.setItem('welcomeMessageShownTime', Date.now());
        }
    }, [openWelcome]);

    useEffect(() => {
        if (currentStep) {
            const updatedSteps = [...completedSteps];
            if (!updatedSteps.includes(currentStep)) {
                updatedSteps.push(currentStep);
                setCompletedSteps(updatedSteps);
                localStorage.setItem('completedTutorialSteps', JSON.stringify(updatedSteps));

                // GTM - Tutorial Step Complete
                window.dataLayer.push({
                    'event': 'tutorial_step_complete',
                    'step_id': currentStep,
                    'step_name': cards.find(card => card.id === currentStep)?.title,
                    'consultant_id': consultant?.id,
                    'completed_steps': updatedSteps.length,
                    'total_steps': cards.length
                });

                // Se completou todos os passos
                if (updatedSteps.length === cards.length) {
                    localStorage.setItem('onboardingComplete', 'true');

                    // GTM - Tutorial Complete
                    window.dataLayer.push({
                        'event': 'tutorial_complete',
                        'consultant_id': consultant?.id,
                        'total_time': Date.now() - parseInt(localStorage.getItem('tutorialStartTime') || Date.now())
                    });

                    // enviar para api que onboarding foi concluido
                    FinishOnboarding();
                }
            }
        }
    }, [currentStep, consultant?.id, completedSteps]);

    const nextStepIndex = cards.findIndex(card => !completedSteps.includes(card.id));
    const progress = (completedSteps.length / cards.length) * 100;

    // Registra o tempo de início do tutorial
    useEffect(() => {
        if (!localStorage.getItem('tutorialStartTime')) {
            localStorage.setItem('tutorialStartTime', Date.now().toString());
        }
    }, []);

    const handleFeatureClick = (feature) => {
        if (feature.path === 'site') {
            window.open(`https://consultores.trainme.com.br/consultor/${consultant.id}`, '_blank');
        } else {
            navigate(feature.path);
        }

        // GTM - Feature Click
        window.dataLayer.push({
            'event': 'onboarding_success_feature_click',
            'feature_id': feature.id,
            'feature_name': feature.title,
            'consultant_id': consultant?.id
        });
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 4,
                backgroundColor: '#f5f5f5'
            }}
        >
            <WelcomeDialog
                open={openWelcome}
                onClose={handleCloseWelcome}
            />

            {completedSteps.length !== cards.length &&
                <Box sx={{ textAlign: 'center', mb: 6, width: '100%', maxWidth: 'md' }}>
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            fontWeight: 600,
                            color: '#662d91',
                            mb: 3
                        }}
                    >
                        Bem-vindo à sua jornada, {consultant?.name?.split(' ')[0]}!
                    </Typography>
                    <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', mb: 4 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography variant="body2" color="text.secondary" fontWeight="500">
                                {completedSteps.length === cards.length
                                    ? 'Tutorial completado!'
                                    : 'Complete as etapas para aproveitar todo o potencial da plataforma'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" fontWeight="500">
                                {Math.round(progress)}%
                            </Typography>
                        </Box>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: 8,
                                borderRadius: 4,
                                backgroundColor: 'rgba(102, 45, 145, 0.2)',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#662d91',
                                    borderRadius: 4,
                                }
                            }}
                        />
                    </Box>
                </Box>
            }

            {completedSteps.length === cards.length ? (
                <Box sx={{ width: '100%', height: '100vh' }}>
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h4" sx={{ fontWeight: 700, color: '#662d91', mb: 2 }}>
                            🎉 Parabéns! Sua jornada está apenas começando!
                        </Typography>
                        <Typography variant="h6" color="text.secondary" sx={{ mb: 4, maxWidth: 800, mx: 'auto' }}>
                            Você já domina o básico! Conheça outros recursos incríveis que preparamos para você:
                        </Typography>
                    </Box>

                    <Swiper
                        modules={[Navigation, Pagination, A11y, EffectFade]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        effect="fade"
                        style={{
                            height: 'calc(100vh - 250px)',
                            maxHeight: '600px'
                        }}
                        breakpoints={{
                            768: {
                                style: {
                                    height: 'calc(100vh - 250px)'
                                }
                            }
                        }}
                    >
                        {additionalFeatures.map((feature) => (
                            <SwiperSlide key={feature.id}>
                                <FeatureCard
                                    feature={feature}
                                    onClick={handleFeatureClick}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/')}
                            sx={{
                                backgroundColor: '#662d91',
                                '&:hover': {
                                    backgroundColor: '#553872'
                                }
                            }}
                        >
                            IR PARA A PLATAFORMA
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Grid container spacing={3} maxWidth="md">
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} key={card.id} sx={{ width: '100%' }}>
                            <TutorialCard
                                card={card}
                                isCompleted={completedSteps.includes(card.id)}
                                isNext={index === nextStepIndex}
                                onClick={() => handleCardClick(card)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default OnboardingSuccess; 