import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, IconButton, Modal, Switch, TextField, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';
import Select from '../../../../../../Components/Select/Select';

import { CreateExercise } from "../../../../../../Services/Exercises/index";

import { ExercisesContext } from '../../../../../../Context/ExercisesContext';

import './ModalNewExercise.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    executionMethod: yup.string().required("O campo forma de execução é obrigatório!"),
    repetitions: yup.string().required("O campo quantidade de repetições é obrigatório!"),
    series: yup.number().required("O campo quantidade de séries é obrigatório!"),
    rest: yup.number().required("O campo de descanso é obrigatório!"),
    cadency: yup.string().required("O campo cadência é obrigatório!"),
    observations: yup.string(),
});

const ModalNewExercise = ({ open, handleModal, exercise, trainingId }) => {
    const serieDefault = { repetitions: '12', cadency: '1:1', rest: 60 };
    const access_token = localStorage.getItem('access_token');
    const { exercises, setExercises } = useContext(ExercisesContext);
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [method, setMethod] = useState('');
    const [customSeries, setCustomSeries] = useState(false);
    const [series, setSeries] = useState([serieDefault]);

    const handleAddExercise = async (data) => {
        window.dataLayer.push({
            'event': 'add_exercise',
            'exercise_name': data.name,
            'exercise_method': data.executionMethod,
            'exercise_series': data.series,
            'training_id': trainingId
        });
        
        let totalSeries = String(data.series);
        let repetitions = series[0].repetitions;
        let rest = series[0].rest.toString();
        let cadency = series[0].cadency;

        if (customSeries) {
            repetitions = series.map(s => s.repetitions).join(';');
            cadency = series.map(s => s.cadency).join(';');
            rest = series.map(s => s.rest).join(';');
            totalSeries = String(series.length);
        }

        const lastItem = exercises[exercises.length - 1];
        const payload = {
            training: trainingId,
            exerciseLibrary: exercise.id,
            name: data.name,
            group: data.group,
            executionMethod: data.executionMethod,
            series: totalSeries,
            repetitions,
            rest,
            cadency,
            observations: data.observations,
            order: lastItem ? lastItem.order + 1 : 1,
            spareExercise: undefined,
            linkedExercises: []
        };
        const exerciseData = await CreateExercise(access_token, payload);
        setExercises(
            [
                ...exercises,
                {
                    ...payload,
                    id: exerciseData.data.id,
                    order: exerciseData.data.order,
                    exerciseLibrary: { id: exercise.id, media: exercise.media, image: exercise.image }
                }
            ]
        );
    };

    const onSubmitHandler = (payload) => {
        handleAddExercise({ ...payload, group: exercise.group });
        handleModal();
    };

    useEffect(() => {
        if (!customSeries) {
            setSeries([{ repetitions: '12', cadency: method === 'HIIT' ? '60' : '1:1', rest: 60 }]);
        }
    }, [customSeries, method]);

    useEffect(() => {
        if (exercise) {
            setValue('name', exercise.name);
            setValue('executionMethod', 'Padrão');
            setMethod('Padrão');
            setValue('repetitions', '12');
            setValue('series', '4');
            setValue('rest', 60);
            setValue('cadency', '1:1');
            setValue('observations', '');
        }
    }, [setValue, exercise]);

    return (
        <Modal
            open={open}
            onClose={handleModal}
        >
            <div className='modal-new-exercise'>
                <form className='modal-add' id='edit-exercise-form'>
                    <Box sx={{ p: 2 }} className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"}
                                    alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Adicionar exercício" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                            onClick={handleModal} />
                    </Box>

                    <Box sx={{
                        overflow: { xs: 'scroll', sm: 'unset' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        px: 3,
                        py: 2
                    }}
                    >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="name"
                                label="Nome do exercício"
                                fullWidth
                                errors={errors.name}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                            <Select
                                disableClearable
                                name="executionMethod"
                                options={['Padrão', 'Rest and Pause', 'Drop Set', 'Bi-set', 'Tri-set', 'Super Série', 'Circuito', 'FST-7', 'Cluster Set', 'HIIT']}
                                defaultValue={'Padrão'}
                                setValue={(data) => {
                                    setMethod(data);
                                    setValue('executionMethod', data);
                                    if (data === 'HIIT') {
                                        setValue('cadency', 60);
                                    }
                                }}
                                label="Forma de execução"
                                control={control}
                                errors={errors.executionMethod}
                                fullWidth
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Switch checked={customSeries} onChange={() => setCustomSeries(!customSeries)} color="secondary" />
                            <Typography>Customizar séries</Typography>
                        </Box>

                        {!customSeries && <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <TextFieldTrainMe
                                control={control}
                                name="series"
                                defaultValue=""
                                type="number"
                                label="Quantidade de séries"
                                fullWidth
                                errors={errors.series} />
                        </Box>}

                        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', pt: 1, overflowY: customSeries ? 'scroll' : '', overflowX: customSeries ? 'hidden' : '', maxHeight: 200 }}>
                            {series && series.map((s, i) => <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                {customSeries && <Typography fontWeight={500}>S{i + 1}</Typography>}
                                {method !== 'HIIT' && <TextField
                                    control={control}
                                    name="repetitions"
                                    onChange={(e) => {
                                        const updatedSeries = [...series];
                                        updatedSeries[i].repetitions = e.target.value;
                                        setSeries(updatedSeries);
                                    }}
                                    value={s.repetitions}
                                    label="Repetições"
                                    fullWidth
                                    variant="outlined"
                                    errors={errors.repetitions} />
                                }
                                <TextField
                                    control={control}
                                    name="cadency"
                                    defaultValue={method === 'HIIT' ? '60' : "1:1"}
                                    label={method === 'HIIT' ? 'Tempo sob tensão' : "Cadência"}
                                    onChange={(e) => {
                                        const updatedSeries = [...series];
                                        updatedSeries[i].cadency = e.target.value;
                                        setSeries(updatedSeries);
                                    }}
                                    value={s.cadency}
                                    fullWidth
                                    variant="outlined"
                                    errors={errors.cadency} />
                                <TextField
                                    control={control}
                                    name="rest"
                                    defaultValue={60}
                                    label={'Descanso (segundos)'}
                                    onChange={(e) => {
                                        const updatedSeries = [...series];
                                        updatedSeries[i].rest = e.target.value;
                                        setSeries(updatedSeries);
                                    }}
                                    value={s.rest}
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    errors={errors.rest} />
                                {customSeries && i !== 0 &&
                                    <IconButton edge="end" aria-label="delete"
                                        onClick={(e) => { setSeries(prevState => [...prevState.slice(1)]) }}>
                                        <Delete sx={{ color: '#c7427b' }} />
                                    </IconButton>}
                            </Box>
                            )}
                        </Box>

                        {customSeries && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                            <Button variant='outlined' onClick={() => setSeries(prevState => [...prevState, serieDefault])} startIcon={<Add />}> Nova série</Button>
                        </Box>}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name="observations"
                                defaultValue=""
                                label="Adicione as observações"
                                fullWidth
                                errors={errors.observations}
                            />
                        </Box>
                    </Box>
                    <div className='button-container'>
                        <PrimaryButton text="Adicionar" onClick={handleSubmit(onSubmitHandler)} />
                    </div>
                </form>
            </div>
        </Modal >
    );
};

export default ModalNewExercise;