import React, { useState } from 'react';

import ModalDelete from '../../../../Components/ModalDelete/ModalDelete';
import FormMeals from './Components/FormMeals/FormMeals';
import ModalAddMeal from './Components/ModalAddMeal/ModalAddMeal';

import { DeleteMeal } from "../../../../Services/Meals/index";
import { RemoveDiet } from "../../../../Services/Diets/index";

const MealsList = ({
    meals,
    setMeals,
    diet,
    onUpdate
}) => {
    const [indexExercise, setIndexExercise] = useState();
    const [modalDelete, setModalDelete] = useState(false);
    const [newMealType, setNewMealType] = useState(1);
    const [modalAddMeal, setModalAddMeal] = useState(false);
    const [meal, setMeal] = useState();
    const [optionToDelete, setOptionToDelete] = useState(null);
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const access_token = localStorage.getItem('access_token');

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleModalEditMeal = (meal, key, optionId = null) => {
        setIndexExercise(key);
        setMeal(meal);
        setNewMealType(1);
        setSelectedOptionId(optionId);
        setModalAddMeal(!modalAddMeal);
    };

    const handleModalDeleteMeal = (meal, optionId = null) => {
        setMeal(meal);
        setNewMealType(1);
        setSelectedOptionId(optionId);
        handleModalDelete();
    };

    const handleDeleteMeal = async (id) => {
        await DeleteMeal(access_token, id);

        if (selectedOptionId) {
            await onUpdate();
        } else {
            setMeals(meals.filter(m => m.id !== id));
        }

        handleModalDelete();
        setSelectedOptionId(null);
    };

    const handleModalAddMeal = (type, optionId = null) => {
        setMeal(undefined);
        setNewMealType(type);
        setModalAddMeal(!modalAddMeal);
        if (optionId) {
            setSelectedOptionId(optionId);
        }
    };

    const handleModalDeleteOption = (option) => {
        setOptionToDelete(option);
        setModalDelete(true);
    };

    const handleDeleteOption = async (id) => {
        try {
            await RemoveDiet(access_token, id);
            await onUpdate();
            setModalDelete(false);
            setOptionToDelete(null);
        } catch (error) {
            console.error('Erro ao remover opção:', error);
            setModalDelete(false);
            setOptionToDelete(null);
        }
    };

    const handleModalClose = async () => {
        setModalAddMeal(false);
        setSelectedOptionId(null);
    };

    return (
        <>
            <FormMeals
                title="Alimentos da refeição"
                diet={diet}
                reposition={false}
                meals={meals}
                description={diet.description}
                handleModalAddMeal={() => handleModalAddMeal(1)}
                handleModalEditMeal={handleModalEditMeal}
                handleModalDeleteMeal={handleModalDeleteMeal}
            />

            {diet && diet.options && diet.options.length > 0 && diet.options.map((option) => (
                <FormMeals
                    key={option.id}
                    title={`Alimentos da opção: ${option.name}`}
                    reposition
                    diet={option}
                    description={option.repositionDescription}
                    meals={option.food || []}
                    handleModalAddMeal={() => handleModalAddMeal(1, option.id)}
                    handleModalEditMeal={(meal, key) => handleModalEditMeal(meal, key, option.id)}
                    handleModalDeleteMeal={(meal) => handleModalDeleteMeal(meal, option.id)}
                    handleDeleteOption={() => handleModalDeleteOption(option)}
                    showDeleteOption
                />
            ))}

            {modalAddMeal && (
                <ModalAddMeal
                    mealId={selectedOptionId || diet.id}
                    handleModal={handleModalClose}
                    mealIndex={indexExercise}
                    mealItem={meal}
                    isReposition={newMealType === 2}
                    meals={selectedOptionId
                        ? (diet.options.find(opt => opt.id === selectedOptionId)?.food || [])
                        : meals
                    }
                    setMeals={async (updatedMeals) => {
                        if (selectedOptionId) {
                            await onUpdate();
                        } else {
                            setMeals(updatedMeals);
                        }

                        setModalAddMeal(false);
                        setSelectedOptionId(null);
                    }}
                />
            )}

            {modalDelete && !optionToDelete && (
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir alimento"
                    subtitle="Deseja excluir o alimento:"
                    text={meal.name}
                    id={meal.id}
                    handleDelete={handleDeleteMeal}
                />
            )}

            {modalDelete && optionToDelete && (
                <ModalDelete
                    handleModal={() => {
                        setModalDelete(false);
                        setOptionToDelete(null);
                    }}
                    title="Excluir opção"
                    subtitle="Deseja excluir a opção de refeição:"
                    text={optionToDelete.name}
                    id={optionToDelete.id}
                    handleDelete={handleDeleteOption}
                />
            )}
        </>
    );
};

export default MealsList;